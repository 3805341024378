export default [
  {
    code: `sv`,
    title: `Svenska`,
    url: `https://www.vastervikoutdoor.com`,
  },
  {
    code: `en`,
    title: `English`,
    url: `https://en.vastervikoutdoor.com`,
  },
]
