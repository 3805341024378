import { rgba } from "polished"

let colors = {
  aquaBlue: `#00a2b5`,
  white: `#fff`,
  black: `#29292b`,
  darkerBlack: `#1e1e1e`,
  metallic: `#43484c`,
  cream: `#efece8`,
  creamLight: `#f4f2ef`,
  gold: `#896c4c`,
  yellow: `#ffcd00`,
  yellowAlt: rgba(250, 230, 170, 0.5),
  yellowOpaque: rgba(250, 230, 170, 1),
  brown: `#bb8b6f`,
  purple: `#735967`,
  blue: `#006a9e`,
  lightBlue: `#dce6f0`,
  lightGreen: rgba(165, 201, 130, 0.5),
  olive: `#797a62`,
  green: `#41885c`,
  greenAlt: `#006e6e`,
  greenOpaque: rgba(165, 201, 130, 1),
  greenNav: rgba(0, 110, 110, 0.5),
  darkGreenAlt: `#003c3c`,
  offWhite: `#fafaf0`,
  offBlue: `#d7f0f0`,
  red: `#884141`,
  naturkartanMapBg: `#f3f3ef`,
  darkGrey: `#333333`,
  greyLight: `#b2c8c7`,
}

colors = {
  ...colors,
  // by function:
  imageBackground: colors.green,
}

export default colors
