import React, { useRef } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import { ReactComponent as SvgMagnifyingGlass } from "../../assets/images/icons/magnifying-glass.svg"

export default function SearchForm({
  formProps = {},
  inputProps = {},
  buttonProps = {},
}) {
  const { site, translations } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
        }
      }

      translations: translationsJson {
        search {
          title
          slug
        }
      }
    }
  `)

  const inputRef = useRef()

  return (
    <Form
      action={`/${translations.search.slug}`}
      method="get"
      {...formProps}
      onSubmit={(e) => {
        e.preventDefault()
        formProps?.onSubmit?.(e)
        navigate(`/${translations.search.slug}/${inputRef.current.value}/`)
      }}
    >
      <Input
        type="search"
        name="query"
        placeholder={`${translations.search.title} ${site.siteMetadata.name}`}
        {...inputProps}
        ref={(node) => {
          inputRef.current = node
          switch (typeof inputProps?.ref) {
            case `function`: {
              inputProps.ref(node)
              break
            }
            case `object`: {
              inputProps.ref.current = node
              break
            }
          }
        }}
      />

      <Button type="submit" title={translations.search.title} {...buttonProps}>
        <SvgMagnifyingGlass aria-label={translations.search.title} />
      </Button>
    </Form>
  )
}

const Form = styled.form`
  position: relative;
  display: flex;
`

const Input = styled.input`
  width: 100%;
  height: ${rem(50)};
  padding: 0 ${rem(60)} 0 ${rem(30)};
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.metallic};
  border-radius: 999em;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.metallic};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.blue};
  }
`

const Button = styled.button`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

  width: ${rem(50)};
  height: ${rem(50)};
  margin-left: ${rem(-55)};
  color: ${({ theme }) => theme.colors.metallic};

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }

  svg {
    width: 100%;
    height: 100%;
    padding: ${rem(12)};
    display: block;
  }
`
