import styled, { css } from "styled-components"

const warningModifier = css`
  &.--warning {
    color: ${({ theme }) => theme.colors.red};
  }
`

const paragraphStyles = css`
  font-size: 1.125em;
  word-break: break-word;
  color: ${({ theme }) => theme.colors.metallic};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: 1em;
  }

  ${warningModifier}
`

const paragraphSmallStyles = css`
  word-break: break-word;
  color: ${({ theme }) => theme.colors.metallic};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: 0.875em;
  }

  ${warningModifier}
`

const paragraphLargeStyles = css`
  font-size: 1.25em;
  word-break: break-word;
  color: ${({ theme }) => theme.colors.metallic};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: 1.125em;
  }

  ${warningModifier}
`

const Paragraph = styled.p`
  ${paragraphStyles}
`

const ParagraphSmall = styled.p`
  ${paragraphSmallStyles}
`

const ParagraphLarge = styled.p`
  ${paragraphLargeStyles}
`

export {
  Paragraph,
  ParagraphSmall,
  ParagraphLarge,
  paragraphStyles,
  paragraphSmallStyles,
  paragraphLargeStyles,
}
