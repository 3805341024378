import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading4 } from "../styled/heading"
import { ParagraphSmall } from "../styled/paragraph"

export default function SiteCard({ data: { name, path, image, excerpt } }) {
  return (
    <Container>
      <Link to={path}>
        <Media>
          {image && image.mediaType !== `video` && (
            <GatsbyImage image={image} alt={name} />
          )}

          {image && image.mediaType == `video` && (
            <video src={image.url} autoPlay muted playsInline loop />
          )}
        </Media>

        <Text>
          {name && (
            <Heading4 as="h3" dangerouslySetInnerHTML={{ __html: name }} />
          )}

          {excerpt && (
            <Description
              dangerouslySetInnerHTML={{
                __html: excerpt,
              }}
            />
          )}
        </Text>
      </Link>
    </Container>
  )
}

const Media = styled.figure`
  width: 100%;
  height: ${rem(360)};
  position: relative;
  overflow: hidden;
  border-radius: ${rem(10)};
  background-color: ${({ theme }) => theme.colors.lightGreen};
  transition: opacity 0.3s ${({ theme }) => theme.easings.default};

  @media ${({ theme }) => theme.mq.mediumDown} {
    height: ${rem(200)};
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    display: block;
  }

  video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    background-color: ${({ theme }) => theme.colors.black};
  }
`

const Description = styled(ParagraphSmall)``

const Text = styled.div`
  padding: ${rem(15)};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(10)};
  flex-grow: 1;
`

const Container = styled.article`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.offBlue};
  border-radius: ${rem(10)};

  ${Heading4} {
    padding-bottom: ${rem(8)};
  }

  a {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:hover {
      ${Media} {
        opacity: 0.8;
      }

      ${Heading4} {
        color: ${({ theme }) => theme.colors.blue};
      }
    }

    &:active {
      ${Media} {
        transition: none;
        opacity: 0.6;
      }
    }
  }
`
