import styled, { css } from "styled-components"

const iframeStyles = css`
  width: 100% !important; // override inlined styles
  height: auto !important; // override inlined styles
  aspect-ratio: 16 / 9;
  display: block;
`

const Iframe = styled.iframe`
  ${iframeStyles}
`

export { Iframe, iframeStyles }
