import { rem, rgba } from "polished"

const infobox = {
  color: ({ theme }) => theme.colors.black,
  backgroundColor: ({ theme }) => theme.colors.white,
  borderColor: ({ theme }) => rgba(theme.colors.blue, 0.2),
  iconColor: ({ theme }) => theme.colors.brown,
  br: rem(10),
}

export default infobox
