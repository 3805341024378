module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"34eecb11-61d0-481f-8341-0f12c98ae9b0","guideId":"307","siteUrl":"https://www.vastervikoutdoor.com","lang":"sv","typesCategories":{"diving":["28"],"hiking":["33","109","116","7","61"],"climbing":["17","125"],"canoeing":["37"],"biking":["31","16"],"wintersports":["23","21","8","175"],"motion":["32","103","27","9","110"]},"typesCategoriesIncluded":{"diving":["28"],"hiking":["33","109","116","7","61"],"climbing":["17","125"],"canoeing":["37"],"biking":["31","16"],"wintersports":["23","21","8","175"],"motion":["32","103","27","9","110"]},"imagePlaceholderColor":"#41885c","templates":{"type":"/build/45f1a313-43f6-40f7-80ba-1a182de1dc7c/src/templates/type.js","site":"/build/45f1a313-43f6-40f7-80ba-1a182de1dc7c/src/templates/site.js","page":"/build/45f1a313-43f6-40f7-80ba-1a182de1dc7c/src/templates/page.js","articles":"/build/45f1a313-43f6-40f7-80ba-1a182de1dc7c/src/templates/articles.js","articlesPost":"/build/45f1a313-43f6-40f7-80ba-1a182de1dc7c/src/templates/articles-post.js","news":"/build/45f1a313-43f6-40f7-80ba-1a182de1dc7c/src/templates/news.js","newsPost":"/build/45f1a313-43f6-40f7-80ba-1a182de1dc7c/src/templates/news-post.js","events":"/build/45f1a313-43f6-40f7-80ba-1a182de1dc7c/src/templates/events.js","eventsPost":"/build/45f1a313-43f6-40f7-80ba-1a182de1dc7c/src/templates/events-post.js","search":"/build/45f1a313-43f6-40f7-80ba-1a182de1dc7c/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Västervik Outdoor","short_name":"Västervik Outdoor","description":"Västervik Outdoor – Inspiration inför din resa och en guide på plats i naturen.","theme_color":"#005096","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
