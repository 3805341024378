import React, { useState, useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem, hideVisually } from "polished"
import { intersection } from "lodash"
import { Meta, Map } from "@outdoormap/gatsby-plugin-brand-sites"

import PostsHighlights from "../components/posts-highlights"
import EventsUpcoming from "../components/events-upcoming"
import NewsLatest from "../components/news-latest"
import Layout from "../components/layout"
import TypeHero from "../components/type-hero"
import CardsCarousel from "../components/cards-carousel"
import SitesFilter from "../components/sites-filter"
import SiteCard from "../components/site-card"
import Styled from "../components/styled"
import { ReactComponent as SvgBulb } from "../assets/images/icons/bulb.svg"
import { typesCategoriesPreselected } from "../config/type"

const collectCategories = (data) => {
  const categories = {}
  data.nodes.forEach(
    (n) => n.mainCategory && (categories[n.mainCategory.id] = n.mainCategory)
  )
  return Object.values(categories)
}

const collectTags = (data) => {
  const tags = {}
  data.nodes.forEach((n) => n.tags.forEach((t) => (tags[t.id] = t)))
  return Object.values(tags)
}

const filterSites = (sites, selectedCategories, selectedTags) => {
  if (!selectedCategories.length && !selectedTags.length) return sites
  return sites.filter((site) => {
    let include = false
    if (selectedCategories.length && site.mainCategory) {
      include = selectedCategories.includes(site.mainCategory.id)
      if (!include) {
        include = !!site.categories.find((c) =>
          selectedCategories.includes(c.id)
        )
      }
    }
    if (selectedTags.length && !include) {
      include = !!selectedTags.find((id) => site.tags.find((t) => t.id == id))
    }
    return include
  })
}

export default function TypeTemplate({
  data: {
    config,
    translations,
    sites,
    latestArticles,
    latestEvents,
    latestNews,
    heroImg,
  },
}) {
  const type = config.types[0]
  const configCategoriesPreselected = typesCategoriesPreselected[type.name]
  const categories = useMemo(() => collectCategories(sites), [sites])
  const tags = useMemo(() => collectTags(sites), [sites])
  const getCategoriesIds = () => categories.map((c) => c.id)
  const getTagsIds = () => tags.map((t) => t.id)
  const preselectedCategories = configCategoriesPreselected.length
    ? intersection(typesCategoriesPreselected[type.name], getCategoriesIds())
    : getCategoriesIds()
  const preselectedTags = configCategoriesPreselected.length ? [] : getTagsIds()
  const [selectedCategories, setSelectedCategories] = useState(
    preselectedCategories
  ) // eslint-disable-line prettier/prettier
  const [selectedTags, setSelectedTags] = useState(preselectedTags)

  const sitesFiltered = useMemo(
    () =>
      filterSites(sites.nodes, selectedCategories, selectedTags).map((s) => (
        <SiteCard key={s.id} data={s} />
      )),
    [sites.nodes, selectedCategories, selectedTags]
  )

  const mapQuery = [`guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`]
  selectedCategories.forEach((c) => mapQuery.push(`category=${c}`))

  const mapQueryChange = (query) => {
    const newCategories = query
      .map((q) =>
        q.type == `category` && getCategoriesIds().includes(q.value)
          ? q.value
          : null
      )
      .filter(Boolean)

    setSelectedCategories(newCategories)
  }

  useEffect(() => {
    if (
      sites.nodes.length &&
      !selectedCategories.length &&
      !selectedTags.length
    ) {
      setSelectedCategories(getCategoriesIds())
      setSelectedTags(getTagsIds())
    }
  }, [selectedCategories, selectedTags])

  return (
    <Layout>
      <Container>
        <TypeHero type={type} img={heroImg} />

        <MapWrap>
          <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

          <Map
            onQueryChange={mapQueryChange}
            query={mapQuery.join(`&`)}
            menu="fullscreen"
            scrollZoom="false"
            styleControl="fullscreen"
            zoomControl="true"
            measureControl="fullscreen"
            locationControl="fullscreen"
            printControl="fullscreen"
            legendControl="true"
            legend="true"
          />
        </MapWrap>

        <SitesFilter
          categories={categories}
          tags={tags}
          selectedCategories={selectedCategories}
          selectedTags={selectedTags}
          onSelectCategories={setSelectedCategories}
          onSelectTags={setSelectedTags}
        />

        <SitesGridWrap>
          <CardsCarousel cards={sitesFiltered} visibleSlides={3} />
        </SitesGridWrap>

        {!!latestNews.nodes.length && (
          <NewsWrap>
            <NewsLatest
              data={latestNews.nodes}
              heading={config.pages.news.title}
              moreText={translations.global.seeAll}
              moreLink={`/${config.pages.news.slug}`}
            />
          </NewsWrap>
        )}

        {!!latestEvents.nodes.length && (
          <EventsWrap>
            <EventsUpcoming
              data={latestEvents.nodes}
              heading={config.pages.events.title}
              moreText={translations.global.seeAll}
              moreLink={`/${config.pages.events.slug}`}
            />
          </EventsWrap>
        )}

        <PostsHighlights
          data={latestArticles.nodes}
          heading={translations.global.latestArticles}
          moreText={translations.global.seeAll}
          moreLink={`/${config.pages.articles.slug}`}
          icon={<SvgBulb />}
        />

        <Description>
          <Styled
            dangerouslySetInnerHTML={{
              __html: type.description,
            }}
          />
        </Description>
      </Container>
    </Layout>
  )
}

export const Head = ({ data: { config, heroImg } }) => {
  const type = config.types[0]

  return (
    <Meta
      data={{
        title: type.title,
        description: type.tagline,
        socialImage: heroImg?.childImageSharp.social.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($type: String!, $categories: [String]!) {
    translations: translationsJson {
      global {
        map
        seeAll
        latestArticles
      }
    }

    config: configJson {
      types(name: $type) {
        name
        slug
        color
        title
        tagline
        description
      }

      pages {
        articles {
          slug
        }

        events {
          title
          slug
        }

        news {
          title
          slug
        }
      }
    }

    sites: allSitesJson(
      filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      sort: { name: ASC }
    ) {
      nodes {
        ...SitesCardFragment

        categories {
          id: nk_id
        }

        tags(type: "locality") {
          id
          name
        }
      }
    }

    heroImg: file(
      dir: { regex: "/src/assets/images/content$/i" }
      name: { eq: $type }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
        social: gatsbyImageData(
          width: 1600
          height: 628
          sizes: null
          transformOptions: { fit: COVER, cropFocus: ATTENTION }
        )
      }
    }

    latestArticles: allArticlesJson(
      filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      sort: { publish_at: DESC }
      limit: 3
    ) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    latestEvents: allEventsJson(
      filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      sort: { occurs_at: ASC }
      limit: 3
    ) {
      nodes {
        ...EventsCardFragment
      }
    }

    latestNews: allNewsJson(
      filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      sort: { publish_at: DESC }
      limit: 3
    ) {
      nodes {
        ...NewsCardFragment
      }
    }
  }
`

const Container = styled.div`
  padding-bottom: ${rem(40)};

  @media ${({ theme }) => theme.mq.largeDown} {
    padding-bottom: ${rem(30)};
  }
`

const SitesGridWrap = styled.div`
  ${({ theme }) => theme.grid.container()}

  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(30)};
    margin-bottom: ${rem(30)};
  }
`

const MapWrap = styled.div`
  height: 60vh;
  min-height: ${rem(260)};
  max-height: ${rem(640)};
  margin-bottom: ${rem(40)};
  /* margin-top: ${rem(40)}; */

  /* @media ${({ theme }) => theme.mq.largeDown} {
    margin-bottom: ${rem(30)};
  } */

  * {
    border-radius: ${rem(10)};
  }

  > h2 {
    ${hideVisually()}
  }
`

const EventsWrap = styled.div`
  margin-top: ${rem(80)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(30)};
  }
`

const NewsWrap = styled.div`
  margin-top: ${rem(80)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(30)};
  }
`

const Description = styled.div`
  ${({ theme }) => theme.grid.container()}
`
