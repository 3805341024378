import { css } from "styled-components"
import { rem } from "polished"

const hideScrollbar = () => css`
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* Chrome/Safari/Webkit */
    display: none;
  }
`

const overflowMask = (direction = `both`, size = 20) => {
  if (direction === `both`) {
    return css`
      mask: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) ${rem(size)},
        rgba(0, 0, 0, 1) calc(100% - ${rem(size)}),
        rgba(0, 0, 0, 0) 100%
      );
    `
  }

  if (direction === `right`) {
    return css`
      mask: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) calc(100% - ${rem(size)}),
        rgba(0, 0, 0, 0) 100%
      );
    `
  }

  if (direction === `left`) {
    return css`
      mask: linear-gradient(
        to left,
        rgba(0, 0, 0, 1) calc(100% - ${rem(size)}),
        rgba(0, 0, 0, 0) 100%
      );
    `
  }

  return ``
}

export default {
  hideScrollbar,
  overflowMask,
}
