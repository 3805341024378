import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import PostsHighlights from "../components/posts-highlights"
import { Heading2 } from "../components/styled/heading"
import { Paragraph } from "../components/styled/paragraph"

export default function ArticlesTemplate({ data: { config, articles, img } }) {
  return (
    <Layout>
      {img && (
        <Image>
          <GatsbyImage
            image={img.childImageSharp.gatsbyImageData}
            alt={config.pages.articles.heading}
          />
        </Image>
      )}

      <Header>
        <Heading2
          dangerouslySetInnerHTML={{
            __html: config.pages.articles.heading,
          }}
        />

        <Paragraph
          dangerouslySetInnerHTML={{
            __html: config.pages.articles.description,
          }}
        />
      </Header>

      <PostsHighlights data={articles.nodes} />
    </Layout>
  )
}

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.articles.title }} />
}

export const pageQuery = graphql`
  query {
    config: configJson {
      pages {
        articles {
          title
          description
          heading
        }
      }
    }

    articles: allArticlesJson(sort: { publish_at: DESC }) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    img: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/home.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 2560)
      }
    }
  }
`

const Image = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    max-height: ${rem(640)};
  }
`

const Header = styled.header`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${rem(50)};
  padding-bottom: ${rem(50)};
`
