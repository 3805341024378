import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { hideVisually, rem } from "polished"
import { Meta, Map } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Hero from "../components/hero"
import IndexSites from "../components/index-sites"
import PostsHighlights from "../components/posts-highlights"
import { ReactComponent as SvgBulb } from "../assets/images/icons/bulb.svg"
import EventsUpcoming from "../components/events-upcoming"

export default function IndexPage({
  data: {
    config,
    translations,
    latestArticles,
    latestEvents,
    latestNews,
    hiking,
    canoeing,
    climbing,
    fishing,
    biking,
    diving,
    wintersports,
    imgHome,
  },
}) {
  const sites = []

  const findType = (name) => config.types.find((t) => t.name === name)

  if (hiking.nodes.length) {
    sites.push({ type: findType(`hiking`), items: hiking.nodes })
  }

  if (canoeing && canoeing.nodes.length) {
    sites.push({ type: findType(`canoeing`), items: canoeing.nodes })
  }

  if (climbing && climbing.nodes.length) {
    sites.push({ type: findType(`climbing`), items: climbing.nodes })
  }

  if (fishing && fishing.nodes.length) {
    sites.push({ type: findType(`fishing`), items: fishing.nodes })
  }

  if (biking && biking.nodes.length) {
    sites.push({ type: findType(`biking`), items: biking.nodes })
  }

  if (wintersports && wintersports.nodes.length) {
    sites.push({ type: findType(`wintersports`), items: wintersports.nodes })
  }

  if (diving && diving.nodes.length) {
    sites.push({ type: findType(`diving`), items: diving.nodes })
  }

  const mapQuery = [
    `guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`,
    ...[31, 16, 8, 37, 33, 38, 32, 96, 7, 17, 28].map((c) => `category=${c}`), // biking,mountainbiking,downhill,canoeing,hiking,trailrunning,running,pilgrim,walking,climbing,diving
  ].join(`&`)

  return (
    <Layout>
      <Hero
        headingLevel={1}
        data={{
          image: imgHome,
          title: config.pages.index.hero.title,
        }}
      />

      <MapWrap>
        <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

        <Map
          query={mapQuery}
          menu="fullscreen"
          scrollZoom="false"
          styleControl="fullscreen"
          zoomControl="true"
          measureControl="fullscreen"
          locationControl="fullscreen"
          printControl="fullscreen"
          legendControl="true"
          legend="true"
        />
      </MapWrap>

      <HighlightsWrapper>
        <PostsHighlights
          data={latestArticles.nodes}
          heading={translations.global.latestArticles}
          moreText={translations.global.seeAll}
          moreLink={`/${config.pages.articles.slug}`}
          icon={<SvgBulb />}
        />

        <PostsHighlights
          data={latestNews.nodes}
          heading={translations.global.latestNews}
          moreText={translations.global.seeAll}
          moreLink={`/${config.pages.news.slug}`}
          icon={<SvgBulb />}
        />
      </HighlightsWrapper>

      {!!latestEvents.nodes.length && (
        <EventsUpcoming
          data={latestEvents.nodes}
          heading={config.pages.events.title}
          moreText={translations.global.seeAll}
          moreLink={`/${config.pages.events.slug}`}
        />
      )}

      <Sites>
        {sites.map(({ type, items }, i) => (
          <React.Fragment key={i}>
            <IndexSites type={type} sites={items} />
          </React.Fragment>
        ))}
      </Sites>
    </Layout>
  )
}

export const Head = ({ data: { config } }) => {
  return (
    <Meta
      data={{
        title: config.pages.index.title,
        titleOverridePattern: true,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        map
        seeAll
        latestArticles
        latestNews
      }
    }

    config: configJson {
      pages {
        index {
          title
          hero {
            title
          }
        }
        articles {
          slug
        }
        news {
          slug
        }
        events {
          title
          slug
        }
      }

      types {
        name
        slug
        color
        title
        tagline
        description
      }
    }

    hiking: allSitesJson(
      filter: { the_type: { eq: "hiking" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    canoeing: allSitesJson(
      filter: { the_type: { eq: "canoeing" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    climbing: allSitesJson(
      filter: { the_type: { eq: "climbing" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    fishing: allSitesJson(
      filter: { the_type: { eq: "fishing" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    biking: allSitesJson(
      filter: { the_type: { eq: "biking" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    wintersports: allSitesJson(
      filter: { the_type: { eq: "wintersports" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    diving: allSitesJson(
      filter: { the_type: { eq: "diving" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    latestNews: allNewsJson(sort: { publish_at: DESC }, limit: 3) {
      nodes {
        ...NewsCardFragment
      }
    }

    latestEvents: allEventsJson(sort: { occurs_at: ASC }, limit: 6) {
      nodes {
        ...EventsCardFragment
      }
    }

    latestArticles: allArticlesJson(sort: { publish_at: DESC }, limit: 3) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    imgHome: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/home.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 2560)
      }
    }
  }
`

const MapWrap = styled.section`
  height: 80vh;
  min-height: ${rem(220)};
  z-index: 10;
  position: relative;
  margin-bottom: ${rem(20)};
  margin-top: ${rem(40)};

  > h2 {
    ${hideVisually()}
  }
`

const Sites = styled.div`
  background-color: ${({ theme }) => theme.colors.offBlue};

  > .-hr {
    ${({ theme }) => theme.grid.container()}

    div {
      height: 0;
      margin-bottom: ${rem(40)};
      display: block;
      overflow: hidden;
    }
  }
`

const HighlightsWrapper = styled.div`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(20)};
`
