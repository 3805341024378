const typesCategories = {
  diving: [`28`], // diving
  hiking: [`33`, `109`, `116`, `7`, `61`], // hiking, circular-trail, pilgrim-trail, walking, shelter
  climbing: [`17`, `125`], // climbing, bouldering
  canoeing: [`37`], // canoeing
  biking: [`31`, `16`], // biking, mountainbiking
  wintersports: [`23`, `21`, `8`, `175`], // skates, skiing, downhill, sledding
  motion: [`32`, `103`, `27`, `9`, `110`], // running, lighted-trails, outdoor-gym, exercise, swimming-bool/bathhouse
  // service: [], // everything else
}

const typesCategoriesIncluded = typesCategories
const typesCategoriesPreselected = typesCategories

module.exports = {
  typesCategories,
  typesCategoriesIncluded,
  typesCategoriesPreselected,
}
