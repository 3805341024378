import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"

import { Heading1 } from "../styled/heading"
import { Label2 } from "../styled/label"
import TypeIconSvg from "../type-icon-svg"

const TypeHero = ({ type: { title, tagline, name }, img }) => {
  return (
    <Container>
      {img && (
        <Image>
          <GatsbyImage
            image={img.childImageSharp.gatsbyImageData}
            alt={title}
          />
        </Image>
      )}

      <Text>
        <HeadingWrapper>
          <TypeIconSvg name={name} />

          <Heading1 dangerouslySetInnerHTML={{ __html: title }} />
        </HeadingWrapper>

        <Tagline
          dangerouslySetInnerHTML={{
            __html: tagline,
          }}
        />
      </Text>
    </Container>
  )
}

export default TypeHero

const Container = styled.section`
  height: 60vh;
  min-height: ${rem(260)};
  max-height: ${rem(640)};
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 40%;
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.7)
    );
  }
`

const Image = styled.div`
  position: relative;
  z-index: 1;

  &,
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const Text = styled.div`
  padding: ${rem(15)} ${rem(30)};
  position: absolute;
  z-index: 3;
  left: 3%;
  bottom: ${rem(40)};
  border-radius: ${rem(8)};
  color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${rem(5)} ${rem(10)};
  }

  svg {
    width: ${rem(55)};
    height: ${rem(55)};
    margin-right: ${rem(7)};
    position: relative;
    bottom: ${rem(3)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      width: ${rem(42)};
      height: ${rem(42)};
      margin-right: 0;
    }
  }
`

const Tagline = styled(Label2)`
  margin-top: ${rem(10)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${rem(16)};
  }
`

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`
