import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import NewsCard from "../components/news-card"
import { Heading1 } from "../components/styled/heading"
import { Paragraph } from "../components/styled/paragraph"

export default function NewsTemplate({
  data: { config, translations, news, imgCover },
}) {
  const anyNews = !!news.nodes?.length

  return (
    <Layout>
      <Container>
        {imgCover && (
          <Image>
            <GatsbyImage
              image={imgCover.childImageSharp.gatsbyImageData}
              alt={config.pages.news.title}
            />
          </Image>
        )}

        <Content>
          <div>
            <Heading1
              dangerouslySetInnerHTML={{
                __html: config.pages.news.title,
              }}
            />

            {anyNews && (
              <Grid>
                {news.nodes.map((item) => (
                  <li key={item.id}>
                    <NewsCard data={item} />
                  </li>
                ))}
              </Grid>
            )}

            {!anyNews && (
              <Paragraph
                dangerouslySetInnerHTML={{
                  __html: translations.global.noContent,
                }}
              />
            )}
          </div>
        </Content>
      </Container>
    </Layout>
  )
}

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.news.title }} />
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        noContent
      }
    }

    config: configJson {
      pages {
        news {
          title
        }
      }
    }

    news: allNewsJson(sort: { publish_at: DESC }) {
      nodes {
        ...NewsCardFragment
      }
    }

    imgCover: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/events.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
  }
`

const Content = styled.div`
  padding: ${rem(40)} 0;

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }

  > div {
    ${({ theme }) => theme.grid.container()}
  }

  ${Heading1} {
    margin-bottom: ${rem(10)};
  }
`

const Container = styled.div`
  padding-bottom: ${rem(30)};
  background-color: ${({ theme }) => theme.colors.lightBlue};
`

const Image = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    max-height: ${rem(480)};
  }
`

const Grid = styled.ul`
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX(null, { nested: true })}
  ${({ theme }) => theme.grid.gridGutterY(30)}

  margin-top: ${rem(20)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: 0;
  }

  > li {
    ${({ theme }) => theme.grid.cell(4)}

    @media ${({ theme }) => theme.mq.mediumDown} {
      ${({ theme }) => theme.grid.cell(6)}
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      ${({ theme }) => theme.grid.cell(12)}
    }
  }
`
