import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import {
  StoreProvider as BrandSitesStoreProvider,
  NativeApp,
  GlobalNotice,
  CookiesConsent,
} from "@outdoormap/gatsby-plugin-brand-sites"

import Header from "../header"
import Footer from "../footer"
import { Anchor } from "../styled/anchor"
import { Paragraph, ParagraphSmall } from "../styled/paragraph"
import { StoreProvider } from "../../store"
import theme from "../../theme"
import { ReactComponent as SvgDelete } from "../../assets/images/icons/delete.svg"
import "../../assets/stylesheets/index.scss"

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <BrandSitesStoreProvider language={process.env.GATSBY_LANG}>
        <StoreProvider>
          <React.Fragment>
            <InitialStyles />

            <header>
              <GlobalNotice Anchor={Anchor} Paragraph={Paragraph} />

              <Header tagName="div" />
            </header>

            <main>{children}</main>

            <Footer />

            <CookiesConsent Text={ParagraphSmall} />

            <NativeApp
              iosId="1574348968"
              androidId="se.naturkartan.android.vastervik"
              thumbnail="/native-app-thumbnail.webp"
              CloseSvg={SvgDelete}
            />
          </React.Fragment>
        </StoreProvider>
      </BrandSitesStoreProvider>
    </ThemeProvider>
  )
}

const InitialStyles = createGlobalStyle`
  * {
    line-height: calc(2px + 2.3ex + 2px); /* https://hugogiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height/ */
  }

  *:focus:not(:focus-visible) {
    outline: none;
  }

  html {
    ${({ theme }) => theme.fonts.set(`primary`, `normal`)}

    font-size: 100%;  /* a11y */
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.offBlue};
  }

  html,
  body {
    width: 100%;
    overflow-x: hidden;
  }

  strong {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}
  }
`
