import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem, darken } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import Icon from "../icon"
import TypeIconSvg from "../type-icon-svg"

export default function TypeCtas() {
  const {
    config: { types },
  } = useStaticQuery(graphql`
    query {
      config: configJson {
        types {
          name
          slug
          color
          title
        }
      }
    }
  `)

  return (
    <Container>
      <ul>
        {types.map(({ name, slug, color, title }) => (
          <li key={name}>
            <LinkStyled to={`/${slug}`} $color={color}>
              <Icon
                iconSvg={<TypeIconSvg name={name} />}
                iconColor="blue"
                className="-icon"
                aria-hidden="true"
              />

              <span>{title}</span>
            </LinkStyled>
          </li>
        ))}
      </ul>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  max-width: ${rem(960)};

  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media ${({ theme }) => theme.mq.smallDown} {
      flex-direction: column;
    }
  }

  li {
    justify-self: center;
    padding: ${rem(7)};
  }
`

const LinkStyled = styled(Link)`
  width: 100%;
  height: 100%;
  padding: 0.4em 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${rem(10)};
  font-size: ${rem(24)};
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(40)};
  transition: background-color 0.3s ${({ theme }) => theme.easings.default};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${rem(18)};
  }

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.blue)};
    color: ${({ theme }) => theme.colors.white};

    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &:active {
    transition: none;
    background-color: ${({ theme, $color }) =>
      darken(0.2, theme.colors[$color || `metallic`])};
  }

  > .-icon {
    width: 1.8em;
    height: 1.5em;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};

    svg {
      padding: 5%;
    }
  }

  > span {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    white-space: nowrap;
  }
`
