import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import CardsCarousel from "../cards-carousel"
import SiteCard from "../site-card"
import { Heading2 } from "../styled/heading"
import { Paragraph } from "../styled/paragraph"
import { anchorStyles } from "../styled/anchor"
import Icon from "../icon"
import TypeIconSvg from "../type-icon-svg"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function IndexSites({ type, sites }) {
  const anySites = !!sites?.length

  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          seeAll
        }
      }
    }
  `)

  if (!anySites) return null

  return (
    <Container>
      <Header>
        <Icon
          iconSvg={<TypeIconSvg name={type.name} />}
          iconColor="blue"
          bgColor="white"
          className="-icon"
          aria-hidden="true"
        />

        {type.title && (
          <Heading2 dangerouslySetInnerHTML={{ __html: type.title }} />
        )}

        {type.tagline && (
          <Paragraph dangerouslySetInnerHTML={{ __html: type.tagline }} />
        )}
      </Header>

      <CardsCarousel
        cards={sites.map((s) => (
          <SiteCard key={s.id} data={s} />
        ))}
        visibleSlides={3}
      />

      <Cta>
        <Link to={`/${type.slug}`}>
          <span
            dangerouslySetInnerHTML={{ __html: translations.global.seeAll }}
          />

          <SvgArrowRight aria-hidden="true" />
        </Link>
      </Cta>
    </Container>
  )
}

const Header = styled.header`
  display: grid;
  grid-template-areas:
    "a b"
    "a c";
  grid-template-columns: 1fr 13fr;
  grid-template-rows: 1fr 1fr;
  margin-bottom: ${rem(15)};
  padding-top: 0;
  padding-right: ${rem(50)};
  position: relative;
  align-items: center;

  ${Paragraph} {
    text-transform: uppercase;
    grid-area: c;
  }

  > .-icon {
    grid-area: a;
    position: relative;
    bottom: ${rem(4)};
    margin-right: ${rem(20)};
    width: 4.5em;
    height: 4.5em;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};

    svg {
      padding: 5% 10% 10% 20%;
      border-radius: 10px;
      margin-left: 2px;
    }
  }
`
const Cta = styled.footer`
  margin-bottom: ${rem(40)};
  margin-top: ${rem(20)};
  text-transform: uppercase;

  a {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}
    ${anchorStyles}

    color: ${({ theme }) => theme.colors.black};
    z-index: 10;
    position: relative;

    &:hover {
      color: ${({ theme }) => theme.colors.blue};

      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      margin-right: 0.4em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      position: relative;
      bottom: 0.125em;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  width: 100%;
  padding-bottom: ${rem(20)};
`
