import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading4 } from "../styled/heading"

export default function PostCard({
  data: { title, path, image, date },
  titleTag = `h2`,
}) {
  return (
    <Container>
      <Link to={path}>
        <Image>
          {image && image.mediaType !== `video` && (
            <GatsbyImage image={image} alt={title} />
          )}

          {image && image.mediaType == `video` && (
            <Video src={image.url} autoPlay muted playsInline loop />
          )}
        </Image>

        <Text>
          {date && (
            <Date datetime={date} dangerouslySetInnerHTML={{ __html: date }} />
          )}

          {title && (
            <Title as={titleTag} dangerouslySetInnerHTML={{ __html: title }} />
          )}
        </Text>
      </Link>
    </Container>
  )
}

const Image = styled.figure`
  width: 100%;
  height: ${rem(320)};
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gold};
  border-radius: ${rem(5)};
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ${({ theme }) => theme.easings.default};
  border-radius: ${rem(15)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    height: ${rem(200)};
  }

  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: ${({ theme }) => theme.colors.black};
`

const Text = styled.div`
  padding: ${rem(10)};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${rem(15)};
  min-height: ${rem(100)};
`

const Date = styled.time`
  margin-bottom: 0.5em;
  display: block;
  font-size: 0.875em;
  color: ${({ theme }) => theme.colors.metallic};
`

const Title = styled(Heading4)`
  width: 80%;
`

const Container = styled.article`
  width: 100%;

  a {
    display: block;

    &:hover {
      ${Image} {
        opacity: 0.8;
      }

      ${Title} {
        color: ${({ theme }) => theme.colors.blue};
      }
    }

    &:active {
      ${Image} {
        transition: none;
        opacity: 0.6;
      }
    }
  }

  ${Title} {
    width: 80%;
  }
`
