import React from "react"

const StoreContext = React.createContext()
const StoreConsumer = StoreContext.Consumer

const StoreProvider = ({ children }) => {
  // const [headerInverted, setHeaderInverted] = useState(false)

  return (
    <StoreContext.Provider
      value={
        {
          // headerInverted,
          // setHeaderInverted,
        }
      }
    >
      {children}
    </StoreContext.Provider>
  )
}

export { StoreContext, StoreProvider, StoreConsumer }
