import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem, rgba, hideVisually } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Label3, Label4 } from "../styled/label"
import { Heading1 } from "../styled/heading"
import { ParagraphSmall } from "../styled/paragraph"

export default function SiteHero({
  data: { name, location, length, categories } = {},
  type,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          categories
        }
      }
    }
  `)

  return (
    <Container>
      <Inner>
        <Info>
          <TypeLink to={`/${type.slug}`}>
            <Label3 dangerouslySetInnerHTML={{ __html: type.title }} />
          </TypeLink>

          {name && <Heading1 dangerouslySetInnerHTML={{ __html: name }} />}

          {type.name !== `biking` && location && (
            <Location dangerouslySetInnerHTML={{ __html: location }} />
          )}

          {type.name === `biking` && length && <Length>{length} km</Length>}

          {type.name !== `biking` && !!categories?.length && (
            <Categories>
              <Label4
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: translations.global.categories,
                }}
              />

              <ul>
                {categories.map((category) => (
                  <li key={category.id}>
                    <img
                      src={`https://assets.naturkartan.se/assets/categories/${category.id}/main.svg`}
                      alt=""
                      role="presentation"
                    />

                    <span dangerouslySetInnerHTML={{ __html: category.name }} />
                  </li>
                ))}
              </ul>
            </Categories>
          )}
        </Info>
      </Inner>
    </Container>
  )
}

const Container = styled.header`
  width: 100%;
  max-width: ${rem(640)};
  margin-bottom: ${rem(40)};
  padding-bottom: ${rem(20)};
  border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.gold, 0.2)};
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${Heading1} {
    color: ${({ theme }) => theme.colors.black};
  }
`

const TypeLink = styled(Link)`
  margin-bottom: ${rem(20)};
  display: flex;
  align-items: center;

  &:active {
    opacity: 0.6;
  }

  > ${Label3} {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    color: ${({ theme }) => theme.colors.blue};
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${rem(6)} ${rem(14)} ${rem(6)} ${rem(14)};
    border-radius: ${rem(30)};
    position: relative;
    z-index: 10;
    font-size: ${rem(20)};
  }
`

const Location = styled(ParagraphSmall)`
  ${hideVisually()}

  color: ${({ theme }) => theme.colors.metallic};
`

const Length = styled(ParagraphSmall)`
  color: ${({ theme }) => theme.colors.metallic};
`

const Categories = styled.div`
  width: 100%;
  margin-top: auto;
  margin-top: 1.2em;
  position: relative;

  ${Label4} {
    margin-bottom: 1em;
  }

  ul {
    li {
      display: inline-block;
      margin-bottom: 1em;

      &:not(:last-child) {
        margin-right: 1.25em;
      }
    }

    img {
      width: 1.5em;
      margin-top: -0.15em;
      margin-right: 0.75em;
      display: inline-block;
      vertical-align: middle;
    }

    span {
      font-size: 0.875em; /* 14 */
    }
  }
`
