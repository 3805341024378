import React, { useState } from "react"
import { RemoveScroll } from "react-remove-scroll"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { rem, rgba } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import PrimaryNav from "./primary-nav"
import { ReactComponent as DeleteSvg } from "../../assets/images/icons/delete.svg"
import { ReactComponent as MenuSvg } from "../../assets/images/icons/menu.svg"
import logoUrl from "../../assets/images/logo.png"

export default function Header({ tagName = `header` }) {
  const { site, translations } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
        }
      }

      translations: translationsJson {
        global {
          menuHide
          menuShow
        }
      }
    }
  `)

  const [mobileActive, setMobileActive] = useState(false)

  const toggleClick = () => {
    setMobileActive(!mobileActive)
  }

  return (
    <RemoveScroll enabled={mobileActive}>
      <Container as={tagName} $active={mobileActive}>
        <Inner>
          <Bar>
            <Logo>
              <Link to="/" title={site.siteMetadata.name}>
                <img src={logoUrl} alt={site.siteMetadata.name} />
              </Link>
            </Logo>

            <Toggle
              title={
                mobileActive
                  ? translations.global.menuHide
                  : translations.global.menuShow
              }
              onClick={toggleClick}
            >
              {mobileActive ? (
                <DeleteSvg aria-label={translations.global.menuHide} />
              ) : (
                <MenuSvg aria-label={translations.global.menuShow} />
              )}
            </Toggle>
          </Bar>
          <NavWrap>
            <PrimaryNav />
          </NavWrap>
        </Inner>
      </Container>
    </RemoveScroll>
  )
}

const Bar = styled.div`
  flex-shrink: 0;
  order: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.div`
  a {
    height: ${rem(60)};
    margin: 0 auto;
    display: block;
    transition: opacity 0.2s ${({ theme }) => theme.easings.default};

    @media ${({ theme }) => theme.mq.xsmallDown} {
      width: ${rem(100)};
      height: ${rem(50)};
    }

    &:hover {
      opacity: 0.7;
    }

    &:active {
      transition: none;
      opacity: 0.4;
    }
  }

  img {
    margin-left: ${rem(5)};
    height: 100%;
    display: block;
  }
`

const Toggle = styled.button.attrs({ type: `button` })`
  width: 2em;
  height: 2em;
  padding: 0.4em;
  color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.mq.largeUp} {
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const NavWrap = styled.div`
  flex: 1;
  order: 3;
  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.mq.largeDown} {
    display: none;
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid ${({ theme }) => rgba(theme.colors.gold, 0.2)};
  }
`

const Inner = styled.div`
  ${({ theme }) => theme.grid.container()}

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.mq.largeDown} {
    display: block;
  }
`

const Container = styled.header`
  width: 100%;
  padding-top: ${rem(35)};
  padding-bottom: ${rem(35)};
  position: relative;
  z-index: ${({ theme }) => theme.zindex.header};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  height: 14vh;

  @media ${({ theme }) => theme.mq.largeDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }

  ${({ $active }) =>
    $active &&
    css`
      @media ${({ theme }) => theme.mq.largeDown} {
        ${({ theme }) => theme.helpers.hideScrollbar()}

        display: block;
        background-color: ${({ theme }) => theme.colors.white};
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: ${({ theme }) => theme.zindex.headerActive};
        top: 0;
        left: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;

        ${NavWrap} {
          display: block;
        }
      }
    `}
`
