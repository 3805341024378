import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em, rem, rgba, hideVisually } from "polished"
import { shuffle } from "lodash"
import {
  Meta,
  Map,
  Current,
  Infoblocks,
  ImagesCarousel,
} from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Styled from "../components/styled"
import SiteHero from "../components/site-hero"
import Infobox from "../components/infobox"
import CardsCarousel from "../components/cards-carousel"
import SiteCard from "../components/site-card"
import { Label3 } from "../components/styled/label"
import { paragraphLargeStyles } from "../components/styled/paragraph"

export default function SiteTemplate({ data: { translations, site } }) {
  const [nearbySites, setNearbySites] = useState(site?.neighbours || [])

  useEffect(() => {
    setNearbySites(shuffle(site?.neighbours || []))
  }, [])

  const mapQuery = `site_with_neighbours=${site.id}`
  // const mapQuery = [
  //   `site=${site.id}`,
  //   ...nearby.map((n) => `site=${n.id}`),
  // ].join(`&`)

  const NearbyComponent = () => {
    return (
      <Nearby>
        <Label3 as="h2">
          {site.theType == `biking`
            ? translations.global.alongTheWay
            : translations.global.nearby}
        </Label3>

        <CardsCarousel
          cards={nearbySites.map((s) => (
            <SiteCard key={s.id} data={s} />
          ))}
          visibleSlides={2}
        />
      </Nearby>
    )
  }

  return (
    <Layout>
      <article>
        <MapWrap>
          <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

          <Map
            query={mapQuery}
            preselectedSiteId={site.id}
            menu="fullscreen"
            scrollZoom="false"
            styleControl="fullscreen"
            zoomControl="true"
            measureControl="fullscreen"
            locationControl="fullscreen"
            printControl="fullscreen"
            legendControl="fullscreen"
          />
        </MapWrap>

        <Columns>
          <ColumnMain>
            <SiteHero data={site} type={site.theTypeData} />

            <div>
              <CurrentStyled data={site} Title={Label3} Styled={Styled} />

              {site.description && (
                <Content
                  dangerouslySetInnerHTML={{ __html: site.description }}
                />
              )}

              <InfoblocksStyled data={site} Title={Label3} Styled={Styled} />

              {!!nearbySites.length && <NearbyComponent />}
            </div>
          </ColumnMain>

          <ColumnAside>
            {!!site.images?.length && (
              <Images>
                <ImagesCarousel data={site.images} title={site.name} />
              </Images>
            )}

            <Infobox data={site} />
          </ColumnAside>
        </Columns>

        {!!nearbySites.length && (
          <NearbyMobile>
            <NearbyComponent />
          </NearbyMobile>
        )}
      </article>
    </Layout>
  )
}

export const Head = ({ data: { site } }) => {
  return (
    <Meta
      data={{
        title: site.name,
        socialImage: site.images[0]?.images.fallback.src,
        metaDescription: site.metaDescription,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    translations: translationsJson {
      global {
        map
        nearby
        alongTheWay
      }
    }

    site: sitesJson(id: { eq: $id }) {
      id: nk_id
      path
      name
      description
      metaDescription: meta_description
      location
      type
      theType: the_type
      theTypeData: the_type_data {
        name
        color
        slug
        title
      }
      facts
      directions
      address
      telephone
      email
      difficultyText: difficulty_text
      length
      time
      surface
      hills
      vegetation
      current
      parking
      communications
      regulations
      openingHours: opening_hours {
        open
        close
      }
      categories {
        id: nk_id
        name
      }
      links {
        id
        url
        text
      }
      files {
        id
        url
        text
      }
      images {
        ...NKimageFragment
      }
      neighbours {
        ...SitesCardFragment
      }
    }
  }
`

const Nearby = styled.section`
  margin-top: ${rem(40)};
  padding-top: ${rem(40)};
  border-top: 1px solid ${({ theme }) => rgba(theme.colors.gold, 0.2)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(20)};
    padding-top: ${rem(20)};
  }

  ${Label3} {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    color: ${({ theme }) => theme.colors.black};
    margin-bottom: ${rem(20)};
  }
`

const Columns = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)}
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX()}

  margin-top: ${rem(50)};
  margin-bottom: ${rem(50)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(20)};
    margin-bottom: 0;
  }
`

const ColumnMain = styled.div`
  ${({ theme }) => theme.grid.cell(8)}

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.cell(12)}
  }

  > div {
    padding-right: 10%;

    @media ${({ theme }) => theme.mq.mediumDown} {
      padding-right: 0;
    }
  }

  ${Nearby} {
    @media ${({ theme }) => theme.mq.mediumDown} {
      display: none;
    }
  }
`

const ColumnAside = styled.aside`
  ${({ theme }) => theme.grid.cell(4)}

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.cell(12)}

    margin-top: ${rem(30)};
  }
`

const Content = styled(Styled)`
  p:first-child {
    ${paragraphLargeStyles};
    line-height: ${rem(22)};
  }

  > *:not(:first-child) {
    max-width: ${rem(520)};
    line-height: ${rem(20)};
  }
`

const MapWrap = styled.section`
  height: 60vh;
  min-height: ${rem(500)};
  margin-bottom: ${rem(10)};
  overflow: hidden;
  border-radius: ${rem(5)};

  > h2 {
    ${hideVisually()}
  }
`

const NearbyMobile = styled.footer`
  ${({ theme }) => theme.grid.container(`narrow`)}

  margin-bottom: ${rem(40)};
  display: none;

  @media ${({ theme }) => theme.mq.mediumDown} {
    display: block;
  }
`

const Images = styled.div`
  width: 100%;
  aspect-ratio: 4 / 3;
  margin-bottom: ${rem(40)};
  overflow: hidden;
  border-radius: ${rem(5)};
`

const InfoblocksStyled = styled(Infoblocks)`
  margin-top: ${em(50)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(30)};
  }
`

const CurrentStyled = styled(Current)`
  margin: ${em(30)} 0;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin: ${em(20)} 0;
  }
`
