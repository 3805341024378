import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { ParagraphLarge } from "../styled/paragraph"
import { ReactComponent as SvgFacebook } from "../../assets/images/icons/facebook.svg"
import { ReactComponent as SvgTwitter } from "../../assets/images/icons/twitter.svg"
import { ReactComponent as SvgYoutube } from "../../assets/images/icons/youtube.svg"
import { ReactComponent as SvgInstagram } from "../../assets/images/icons/instagram.svg"
import { ReactComponent as SvgChevronLeft } from "../../assets/images/icons/chevron-left.svg"
import { ReactComponent as SvgLogoWhite } from "../../assets/images/logo-white.svg"

export default function About() {
  const { config } = useStaticQuery(graphql`
    query {
      config: configJson {
        footer {
          about
        }
      }
    }
  `)

  return (
    <Container>
      <Logos>
        <Link to="/" title="Västervik Outdoor">
          <SvgLogoWhite />
        </Link>
      </Logos>

      <Description dangerouslySetInnerHTML={{ __html: config.footer.about }} />

      <Wrapper>
        <Social>
          <li>
            <Link
              to="https://www.facebook.com/visitvastervik/"
              target="_blank"
              title="Facebook"
              aria-label="Facebook"
            >
              <SvgFacebook />
            </Link>
          </li>

          <li>
            <Link
              to="https://twitter.com/Vastervik"
              target="_blank"
              title="Twitter"
              aria-label="Twitter"
            >
              <SvgTwitter />
            </Link>
          </li>

          <li>
            <Link
              to="https://www.youtube.com/c/V%C3%A4stervikSweden"
              target="_blank"
              title="Youtube"
              aria-label="Youtube"
            >
              <SvgYoutube />
            </Link>
          </li>

          <li>
            <Link
              to="https://www.instagram.com/visitvastervik/"
              target="_blank"
              title="Instagram"
              aria-label="Instagram"
            >
              <SvgInstagram />
            </Link>
          </li>
        </Social>

        <Back>
          <Link to="https://www.vastervik.com">
            <SvgChevronLeft />
            Besök Västervik.com för mer inspiration om Västervik
          </Link>
        </Back>
      </Wrapper>
    </Container>
  )
}

const Container = styled.section`
  position: relative;
  top: ${rem(-30)};

  @media ${({ theme }) => theme.mq.xlargeDown} {
    top: unset;
  }
`

const Logos = styled.h2`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: ${rem(250)};

  @media ${({ theme }) => theme.mq.xlargeDown} {
    justify-content: center;
  }

  @media ${({ theme }) => theme.mq.xlargeDown} {
    margin: 0 auto;
  }

  a {
    transition: opacity 0.2s ${({ theme }) => theme.easings.default};
    overflow: hidden;
    margin-bottom: ${rem(20)};

    &:hover {
      opacity: 0.8;
    }

    &:active {
      transition: none;
      opacity: 0.5;
    }
  }

  svg {
    width: ${rem(140)};
    margin-top: ${rem(15)};
    display: block;

    @media ${({ theme }) => theme.mq.xlargeDown} {
      right: 0;
    }
  }
`

const Description = styled(ParagraphLarge)`
  color: inherit;
`

const Social = styled.ul`
  margin-right: 1em;

  @media ${({ theme }) => theme.mq.xlargeDown} {
    margin-top: 1.5em;
  }

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  a {
    width: 1.875em;
    height: 1.875em;
    padding: 0.3em;
    display: block;
    transition: color 0.2s ${({ theme }) => theme.easings.default};

    &:hover {
      color: ${({ theme }) => theme.colors.yellowOpaque};
    }

    &:active {
      transition: none;
      color: ${({ theme }) => theme.colors.yellowOpaque};
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const Back = styled.div`
  padding-top: 0.3em;

  a {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    display: flex;
    text-transform: uppercase;

    &:hover {
      color: ${({ theme }) => theme.colors.yellowOpaque};
    }

    &:active {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }

  svg {
    width: 0.8em;
    height: 0.8em;
    margin-top: 0.2em;
    margin-right: 0.4em;
  }
`

const Wrapper = styled.div`
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    margin-top: 1em;
  }
`
