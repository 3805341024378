import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import SearchModal from "../search-modal"
import TypeIconSvg from "../type-icon-svg"
import languages from "../../config/languages"
import { ReactComponent as ChevronDownSvg } from "../../assets/images/icons/chevron-down.svg"
import { ReactComponent as SvgMagnifyingGlass } from "../../assets/images/icons/magnifying-glass.svg"

const ENABLE_SEARCH = !!process.env.GATSBY_TYPESENSE_KEY

const ButtonLink = ({ to, ...props }) =>
  to ? <Link to={to} {...props} /> : <button type="button" {...props} />

export default function PrimaryNav() {
  const {
    pages,
    config: {
      types,
      header: { primaryNav },
    },
    translations: { search },
  } = useStaticQuery(graphql`
    query {
      pages: allPagesJson {
        nodes {
          title
          url: path
        }
      }

      translations: translationsJson {
        search {
          title
          slug
        }
      }

      config: configJson {
        types {
          name
          slug
          color
          title
        }

        header {
          primaryNav: primary_nav {
            types
            # items {
            #   title
            #   url
            # }
            dropdown {
              title
              items {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const [searchModalOpen, setSearchModalOpen] = useState(false)

  const searchModalRequestClose = () => {
    setSearchModalOpen(false)
  }

  const searchButtonClick = (e) => {
    e.preventDefault()
    setSearchModalOpen(true)
  }

  const langCurrent = languages.find((i) => i.code == process.env.GATSBY_LANG)
  const langItems = [
    {
      title: langCurrent.title,
      sub: {
        type: `dropdown`,
        data: languages.filter((lang) => lang.code != langCurrent.code),
      },
    },
  ]

  const items = [
    {
      title: primaryNav.types,
      sub: {
        type: `types`,
        data: types.map((t) => ({ url: `/${t.slug}`, ...t })),
      },
    },
    {
      title: primaryNav.dropdown.title,
      sub: {
        type: `dropdown`,
        data: [...primaryNav.dropdown.items, ...pages.nodes],
      },
    },
    // ...primaryNav.items.map(({ title, url }) => ({ title, url })),
  ]

  return (
    <>
      <Container>
        <Base>
          {items.map((base, i) => (
            <li key={i}>
              <ButtonLink to={base.url}>
                <span dangerouslySetInnerHTML={{ __html: base.title }} />

                {base.sub && <ChevronDownSvg />}
              </ButtonLink>

              {base.sub && (
                <Sub>
                  {base.sub.data.map((sub, j) => (
                    <li key={j}>
                      <Link to={sub.url}>
                        {base.sub.type == `types` && (
                          <TypeIconSvg name={sub.name} />
                        )}

                        <span dangerouslySetInnerHTML={{ __html: sub.title }} />
                      </Link>
                    </li>
                  ))}
                </Sub>
              )}
            </li>
          ))}

          {ENABLE_SEARCH && (
            <li>
              <ButtonLink to={`/${search.slug}`} onClick={searchButtonClick}>
                {search.title}

                <SvgMagnifyingGlass aria-hidden="true" className="--search" />
              </ButtonLink>
            </li>
          )}

          {langItems.map((base, i) => (
            <li key={i}>
              <ButtonLink to={base.url}>
                <span dangerouslySetInnerHTML={{ __html: base.title }} />

                {base.sub && !!base.sub.data.length && <ChevronDownSvg />}
              </ButtonLink>

              {base.sub && !!base.sub.data.length && (
                <Sub>
                  {base.sub.data.map((sub, j) => (
                    <li key={j}>
                      <Link to={sub.url}>
                        {base.sub.data.type == `types` && (
                          <TypeIconSvg name={sub.name} />
                        )}

                        <span dangerouslySetInnerHTML={{ __html: sub.title }} />
                      </Link>
                    </li>
                  ))}
                </Sub>
              )}

              <SearchModal
                isOpen={searchModalOpen}
                onRequestClose={searchModalRequestClose}
              />
            </li>
          ))}
        </Base>
      </Container>

      {ENABLE_SEARCH && (
        <SearchModal
          isOpen={searchModalOpen}
          onRequestClose={searchModalRequestClose}
        />
      )}
    </>
  )
}

const Container = styled.nav`
  a,
  button {
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }

    &:active {
      opacity: 0.6;
    }
  }
`

const Sub = styled.ul`
  width: ${rem(240)};
  padding: 1.5em;
  display: none;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  top: 100%;
  right: 0;
  text-align: right;
  box-shadow: 0 ${rem(4)} ${rem(10)} rgba(0, 0, 0, 0.2);
  border-radius: ${rem(10)};
  animation: 0.2s ${({ theme }) => theme.easings.default};
  animation-name: ${({ theme }) => theme.animations.slideInY(-10)},
    ${({ theme }) => theme.animations.fadeIn};

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
    position: static;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding: 0.75em;
    text-align: left;
    box-shadow: 0 ${rem(2)} ${rem(6)} rgba(0, 0, 0, 0.2);
  }

  li {
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  a {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    display: flex;
    align-items: center;
    font-size: ${rem(18)};
    text-align: left;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  svg {
    width: ${rem(40)};
    height: ${rem(40)};
    margin-right: ${rem(6)};
    flex-shrink: 0;
    color: ${({ theme }) => theme.colors.blue};

    @media ${({ theme }) => theme.mq.largeDown} {
      width: ${rem(26)};
      height: ${rem(26)};
    }
  }
`

const Base = styled.ul`
  display: flex;

  @media ${({ theme }) => theme.mq.largeDown} {
    display: block;
  }

  > li {
    position: relative;

    &:hover,
    &:focus,
    &:focus-within {
      > a,
      > button {
        color: ${({ theme }) => theme.colors.blue};
      }

      ${Sub} {
        display: block;
      }
    }

    > a,
    > button {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

      color: ${({ theme }) => theme.colors.black};
      padding: 0.5em 1em;
      display: block;
      font-size: ${rem(18)};
      text-transform: uppercase;

      @media ${({ theme }) => theme.mq.largeDown} {
        padding: 0.2em 0;
      }

      svg {
        width: 0.7em;
        height: 1em;
        margin-left: 0.4em;
        margin-bottom: 0.15em;
        display: inline-block;

        &.--search {
          width: 1em;
          height: 1em;
        }
      }
    }
  }
`
