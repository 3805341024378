import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em, hideVisually } from "polished"
import {
  Meta,
  Search,
  SearchResult,
} from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import SearchForm from "../components/search-form"
import { Heading1, Heading4 } from "../components/styled/heading"
import { Label3 } from "../components/styled/label"
import { Paragraph, ParagraphSmall } from "../components/styled/paragraph"

export default function SearchTemplate({
  params: { "*": query },
  data: { site, translations },
}) {
  const [inputValue, setInputValue] = useState(query)

  useEffect(() => {
    setInputValue(query)
  }, [query])

  return (
    <Layout>
      <Container>
        <Heading1>{translations.search.title}</Heading1>

        <SearchFormWrap>
          <SearchForm
            inputProps={{
              value: inputValue,
              placeholder: `${translations.search.title} ${site.siteMetadata.name}`,
              onChange: (e) => setInputValue(e.target.value),
            }}
          />
        </SearchFormWrap>

        <Search query={query}>
          {({ status, total, results, page, hasNextPage, showNextPage }) => (
            <>
              {status == `results` && (
                <Info>
                  <strong>{total}</strong>
                  {` `}
                  {translations.search.results?.toLowerCase()}
                </Info>
              )}

              {status == `searching` && (
                <Loader>
                  <span>{translations.search.loadingResults}</span>

                  {Array.from({ length: 3 }).map((_, i) => (
                    <div key={i} />
                  ))}
                </Loader>
              )}

              {[`results`, `error`].includes(status) && total <= 0 && (
                <Empty>{translations.search.noResults}</Empty>
              )}

              {status == `results` && total > 0 && (
                <Results>
                  {results.map((result) => (
                    <SearchResult
                      key={`${result.type}-${result.id}`}
                      data={result}
                      TitleStyled={Heading4}
                      LabelStyled={Label3}
                      DescriptionStyled={Paragraph}
                    />
                  ))}
                </Results>
              )}

              {hasNextPage && (
                <More>
                  <button type="button" onClick={showNextPage}>
                    {translations.search.loadMoreResults}
                  </button>
                </More>
              )}
            </>
          )}
        </Search>
      </Container>
    </Layout>
  )
}

export const Head = ({ query, data: { translations } }) => {
  return (
    <Meta
      data={{
        title: query
          ? `${translations.search.title} “${query}”`
          : `${translations.search.title}`,
        canonical: `/${translations.search.slug}/`,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
      }
    }

    translations: translationsJson {
      search {
        title
        slug
        results
        noResults
        loadingResults
        loadMoreResults
      }
    }
  }
`

const Container = styled.div`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  min-height: 50vh;
  padding-top: ${em(40)};
  padding-bottom: ${em(80)};
`

const SearchFormWrap = styled.div`
  margin-top: ${em(24)};
`

const Info = styled(ParagraphSmall)`
  margin-top: ${em(20)};
  color: ${({ theme }) => theme.colors.metallic};
  text-align: right;
`

const Loader = styled.div`
  margin-top: ${em(30)};
  display: flex;
  flex-direction: column;
  gap: ${em(30)};

  div {
    height: ${em(180)};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${em(8)};
    animation: ${({ theme }) => theme.animations.fadeBlink} 1s
      ${({ theme }) => theme.easings.default} infinite;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  span {
    ${hideVisually()}
  }
`

const Empty = styled(Paragraph)`
  margin-top: ${em(30)};
`

const Results = styled.div`
  margin-top: ${em(50)};
  display: flex;
  flex-direction: column;
  gap: ${em(30)};
`

const More = styled.div`
  padding-top: ${em(20)};
  display: flex;
  justify-content: center;

  button {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    padding: ${em(10)} ${em(30)};
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    border-radius: ${em(5)};
    font-size: ${em(16)};
    text-transform: uppercase;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }
  }
`
