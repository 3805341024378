import { rem } from "polished"

const infobox = {
  color: ({ theme }) => theme.colors.black,
  backgroundColor: ({ theme }) => theme.colors.white,
  closeColor: ({ theme }) => theme.colors.metallic,
  thumbnailBorderColor: ({ theme }) => theme.colors.greyLight,
  thumbnailBorderRadius: rem(5),
  descriptionColor: ({ theme }) => theme.colors.metallic,
  titleFont: ({ theme }) => theme.fonts.set(`primary`, `bold`),
  openFont: ({ theme }) => theme.fonts.set(`primary`, `bold`),
  openColor: ({ theme }) => theme.colors.white,
  openBackgroundColor: ({ theme }) => theme.colors.blue,
  openBorderRadius: rem(999),
}

export default infobox
