import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { chunk } from "lodash"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import PostCardHighlight from "../post-card-highlight"
import { Heading2 } from "../styled/heading"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function PostsHighlights({
  data,
  heading,
  headingLevel = 2,
  moreText,
  moreLink,
}) {
  const dataChunks = useMemo(() => (data?.length ? chunk(data, 3) : []), [data])

  if (!dataChunks.length) return null

  return (
    <Container as={heading ? `section` : `div`}>
      <div>
        {heading && (
          <Heading
            as={`h${headingLevel}`}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}

        {dataChunks.map((chunk, i) => (
          <Highlights key={i} $reversed={(i + 1) % 2 == 0}>
            {chunk.map((item, ii) => (
              <li key={ii}>
                <PostCardHighlight data={item} major={ii == 0} />
              </li>
            ))}
          </Highlights>
        ))}

        {moreText && moreLink && (
          <More>
            <Link to={moreLink}>
              <span dangerouslySetInnerHTML={{ __html: moreText }} />

              <SvgArrowRight aria-hidden="true" />
            </Link>
          </More>
        )}
      </div>
    </Container>
  )
}

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${rem(10)};
  padding-bottom: ${rem(10)};
  background-color: ${({ theme }) => theme.colors.offBlue};
`

const Heading = styled(Heading2)`
  margin-bottom: ${rem(30)};

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-bottom: ${rem(20)};
  }
`

const Highlights = styled.ul`
  display: grid;
  grid-gap: ${rem(15)};
  grid-template-columns: 2fr 1fr;

  & + & {
    margin-top: ${rem(15)};
  }

  ${({ $reversed }) =>
    $reversed &&
    css`
      transform: scale(-1, 1);

      > li {
        transform: scale(-1, 1);
      }
    `}

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: 1fr;
  }

  > li:first-child {
    grid-row: 1 / 3;

    @media ${({ theme }) => theme.mq.mediumDown} and ${({ theme }) =>
        theme.mq.xsmallUp} {
      grid-column: 1 / 3;
    }

    > * {
      @media ${({ theme }) => theme.mq.mediumUp} {
        height: 100%;
      }
    }
  }
`

const More = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
  text-transform: uppercase;

  a {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    color: ${({ theme }) => theme.colors.black};

    &:hover {
      color: ${({ theme }) => theme.colors.blue};

      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      ${anchorStyles}

      margin-right: 0.25em;

      &:hover {
        color: ${({ theme }) => theme.colors.blue};
      }
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      margin-top: -0.1em;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`
