import { ReactComponent as SvgBiking } from "../../assets/images/icons/biking.svg"
import { ReactComponent as SvgHiking } from "../../assets/images/icons/hiking.svg"
import { ReactComponent as SvgWinter } from "../../assets/images/icons/winter.svg"
import { ReactComponent as SvgPaddling } from "../../assets/images/icons/paddling.svg"
import { ReactComponent as SvgClimbing } from "../../assets/images/icons/climbing.svg"
import { ReactComponent as SvgDiving } from "../../assets/images/icons/diving.svg"
import { ReactComponent as SvgFishing } from "../../assets/images/icons/fishing.svg"
import { ReactComponent as SvgHub } from "../../assets/images/icons/hub.svg"
import { ReactComponent as SvgRunning } from "../../assets/images/icons/running.svg"

const icons = {
  stays: SvgBiking,
  eatDrink: SvgBiking,
  hiking: SvgHiking,
  service: SvgHub,
  climbing: SvgClimbing,
  fishing: SvgFishing,
  canoeing: SvgPaddling,
  biking: SvgBiking,
  diving: SvgDiving,
  wintersports: SvgWinter,
  motion: SvgRunning,
}

export default function TypeIconSvg({ name, ...props }) {
  if (!name) return null

  return icons[name](props)
}
