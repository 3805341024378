import { rem } from "polished"

const cookiesConsent = {
  textColor: ({ theme }) => theme.colors.creamLight,
  borderColor: ({ theme }) => theme.colors.black,
  backgroundColor: ({ theme }) => theme.colors.metallic,
  br: rem(8),
  buttonFont: ({ theme }) => theme.fonts.set(`primary`, `bold`),
  buttonTextColor: ({ theme }) => theme.colors.metallic,
  buttonBackgroundColor: ({ theme }) => theme.colors.creamLight,
  buttonBr: rem(5),
}

export default cookiesConsent
