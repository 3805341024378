import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading4 } from "../styled/heading"

export default function EventCard({
  data: { title, image, date, path },
  titleTag = `h2`,
}) {
  return (
    <Container>
      <Link to={path}>
        <Image>{image && <GatsbyImage image={image} alt={title} />}</Image>

        <TextContainer>
          {title && (
            <Title as={titleTag} dangerouslySetInnerHTML={{ __html: title }} />
          )}

          {date && !date.includes(`Invalid`) && (
            <Date datetime={date} dangerouslySetInnerHTML={{ __html: date }} />
          )}
        </TextContainer>
      </Link>
    </Container>
  )
}

const Image = styled.figure`
  width: ${rem(120)};
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  border-radius: ${rem(10)};
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ${({ theme }) => theme.easings.default};
  overflow: hidden;

  @media ${({ theme }) => theme.mq.smallDown} {
    width: 100%;
    height: ${rem(180)};
  }

  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const Date = styled.time`
  margin-top: 0.5em;
  display: block;
  font-size: 0.875em;
  color: ${({ theme }) => theme.colors.metallic};
`

const Title = styled(Heading4)``

const Container = styled.article`
  width: 100%;
  height: 100%;
  display: flex;

  a {
    width: 100%;
    display: grid;
    grid-template-areas: "a b b b";
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${rem(10)};

    @media ${({ theme }) => theme.mq.smallDown} {
      display: block;
    }

    ${Title} {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

      width: 100%;
      font-size: ${rem(20)};
    }

    &:hover {
      ${Image} {
        opacity: 0.8;
      }

      ${Title} {
        color: ${({ theme }) => theme.colors.blue};
      }
    }

    &:active {
      ${Image} {
        transition: none;
        opacity: 0.6;
      }
    }
  }
`
const TextContainer = styled.div`
  grid-area: b;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${rem(20)};
`
