import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { rem } from "polished"

import TypeCtas from "../type-ctas"

export default function Hero({ data, headingLevel = 2 }) {
  if (!data) return null

  return (
    <Container>
      <Media>
        {data.video && (
          <video
            src={data.video}
            preload="auto"
            playsInline
            autoPlay
            loop
            muted
          />
        )}

        {data.image && (
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            alt={data.title}
          />
        )}
      </Media>

      <Content>
        {!!data.title && (
          <Text
            as={`h${headingLevel}`}
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
        )}

        <TypeCtas />
      </Content>
    </Container>
  )
}

const Container = styled.section`
  min-height: 90vh;
  position: relative;
  background-color: ${({ theme }) => theme.colors.offBlue};
  display: flex;
  align-items: center;
`

const Media = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.35)
    );

    @media ${({ theme }) => theme.mq.largeDown} {
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.35)
      );
    }
  }

  video,
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    display: block;
  }

  video {
    object-fit: cover;
  }
`

const Content = styled.div`
  ${({ theme }) => theme.grid.container(`wide`)}

  padding-top: ${rem(80)};
  padding-bottom: ${rem(80)};
  position: relative;
  z-index: 2;
  display: flex;
  gap: ${rem(40)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Text = styled.h2`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

  font-size: ${rem(72)};
  line-height: 1.1;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mq.largeDown} {
    font-size: ${rem(52)};
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    font-size: ${rem(36)};
  }
`
