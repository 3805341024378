import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { /* capitalize, */ sortBy } from "lodash"

// import { ReactComponent as SvgPin } from "../../assets/images/icons/pin.svg"

export default function SitesFilter({
  categories,
  tags,
  selectedCategories,
  // selectedTags,
  onSelectCategories,
  onSelectTags,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          all
        }
      }
    }
  `)

  if (categories.length <= 1 && !tags.length) return null

  categories = sortBy(categories, [`name`])
  tags = sortBy(tags, [`name`])

  const isAll = selectedCategories.length >= categories.length /* &&
    selectedTags.length >= tags.length*/

  const categoryClick = (e) => {
    e.preventDefault()
    const { id } = e.currentTarget.dataset
    let selected = []

    if (isAll) {
      selected.push(id)
    } else {
      selected = [...selectedCategories]
      if (selected.includes(id)) {
        selected = selected.filter((s) => s != id)
      } else {
        selected.push(id)
      }
    }

    onSelectCategories(selected)
    if (isAll) onSelectTags([])
  }

  // const tagClick = (e) => {
  //   e.preventDefault()
  //   const { id } = e.currentTarget.dataset
  //   let selected = []

  //   if (isAll) {
  //     selected.push(id)
  //   } else {
  //     selected = [...selectedTags]
  //     if (selected.includes(id)) {
  //       selected = selected.filter((s) => s != id)
  //     } else {
  //       selected.push(id)
  //     }
  //   }

  //   onSelectTags(selected)
  //   if (isAll) onSelectCategories([])
  // }

  const allClick = () => {
    onSelectCategories(categories.map((c) => c.id))
    onSelectTags(tags.map((t) => t.id))
  }

  return (
    <Container>
      <div>
        <button
          type="button"
          onClick={allClick}
          className={isAll ? `--selected` : undefined}
          dangerouslySetInnerHTML={{ __html: translations.global.all }}
        />

        {categories.map((item) => {
          const isSelected = !isAll && selectedCategories.includes(item.id)

          return (
            <button
              type="button"
              key={item.id}
              className={isSelected ? `--selected` : undefined}
              data-id={item.id}
              onClick={categoryClick}
            >
              <img
                src={`https://assets.naturkartan.se/assets/categories/${item.id}/outline.svg`}
                alt=""
                role="presentation"
              />

              {item.name}
            </button>
          )
        })}

        {/* {tags.map((item) => {
          const isSelected = !isAll && selectedTags.includes(item.id)

          return (
            <button
              type="button"
              key={item.id}
              className={isSelected ? `--selected` : undefined}
              data-id={item.id}
              onClick={tagClick}
            >
              <SvgPin aria-hidden="true" />

              {capitalize(item.name)}
            </button>
          )
        })} */}
      </div>
    </Container>
  )
}

const Container = styled.div`
  ${({ theme }) => theme.grid.container()}
  ${({ theme }) => theme.helpers.hideScrollbar()}
  ${({ theme }) => theme.helpers.overflowMask(`both`)}

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  div {
    display: inline-flex;
  }

  button {
    padding: ${rem(6)} ${rem(16)};
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.metallic};
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid transparent;
    border-radius: 999rem;

    &:not(:last-child) {
      margin-right: ${rem(5)};
    }

    &:hover {
      border-color: ${({ theme }) => theme.colors.blue};
    }

    &.\--selected {
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.white};

      img {
        filter: grayscale(1) brightness(0) invert(1);
      }
    }

    img,
    svg {
      width: ${rem(20)};
      height: ${rem(20)};
      margin-right: ${rem(10)};
    }

    img {
      filter: grayscale(1) brightness(0);
    }

    svg {
      height: ${rem(14)};
    }
  }
`
