import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import {
  ImagesCarousel,
  Map,
  Link,
} from "@outdoormap/gatsby-plugin-brand-sites"

import Infobox from "../infobox"
import CardsCarousel from "../cards-carousel"
import SiteCard from "../site-card"
import PostCard from "../post-card"
import Styled from "../styled"
import { Heading1 } from "../styled/heading"
import { Label3 } from "../styled/label"
import { ParagraphLarge } from "../styled/paragraph"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function Post({
  data,
  other,
  otherHeading,
  otherCta,
  otherCtaUrl,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          mentionedInThisPost
        }
      }
    }
  `)

  const anySites = !!data?.sites?.length
  const mapQuery = anySites
    ? data.sites.map((s) => `site=${s.id}`).join(`&`)
    : null

  if (!data) return null

  return (
    <React.Fragment>
      <Article>
        {!!data?.images?.length && (
          <Images>
            <ImagesCarousel data={data.images} title={data.title} />
          </Images>
        )}

        <Inner>
          <Header>
            {data.date && (
              <Date
                datetime={data.date}
                dangerouslySetInnerHTML={{ __html: data.date }}
              />
            )}

            {data.title && (
              <Title dangerouslySetInnerHTML={{ __html: data.title }} />
            )}

            {data.tagline && (
              <Tagline dangerouslySetInnerHTML={{ __html: data.tagline }} />
            )}
          </Header>

          {data.body && (
            <Content dangerouslySetInnerHTML={{ __html: data.body }} />
          )}

          <InfoboxWrap>
            <Infobox data={data} />
          </InfoboxWrap>

          {mapQuery && (
            <MapWrap>
              <Map
                query={mapQuery}
                menu="fullscreen"
                menuActive="fullscreen"
                filters="fullscreen"
                scrollZoom="false"
                styleControl="fullscreen"
                zoomControl="fullscreen"
                measureControl="fullscreen"
                locationControl="fullscreen"
                printControl="fullscreen"
              />
            </MapWrap>
          )}

          {anySites && (
            <Sites>
              <div>
                <Label3
                  as="h2"
                  dangerouslySetInnerHTML={{
                    __html: `${translations.global.mentionedInThisPost}…`,
                  }}
                />

                <CardsCarousel
                  cards={data.sites.map((s) => (
                    <SiteCard key={s.id} data={s} />
                  ))}
                  visibleSlides={2}
                />
              </div>
            </Sites>
          )}
        </Inner>
      </Article>

      {!!other?.nodes?.length && (
        <Other>
          <div>
            {otherHeading && (
              <Label3
                as="h2"
                dangerouslySetInnerHTML={{ __html: otherHeading }}
              />
            )}
            <CardsCarousel
              cards={other.nodes.map((s) => (
                <PostCard key={s.id} data={s} />
              ))}
              visibleSlides={3}
            />

            {otherCta && otherCtaUrl && (
              <OtherCta>
                <Link to={otherCtaUrl}>
                  <span dangerouslySetInnerHTML={{ __html: otherCta }} />

                  <SvgArrowRight aria-hidden="true" />
                </Link>
              </OtherCta>
            )}
          </div>
        </Other>
      )}
    </React.Fragment>
  )
}

const Article = styled.article``

const Images = styled.div`
  width: 100%;
  height: 50vh;
  min-height: ${rem(240)};
  max-height: ${rem(640)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    height: auto;
    min-height: auto;
    max-height: auto;
    aspect-ratio: 16 / 9;
  }
`

const Inner = styled.div`
  padding: ${rem(50)} 0 ${rem(80)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${rem(30)} 0 ${rem(40)};
  }
`

const Header = styled.header`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-bottom: ${rem(30)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${rem(20)};
  }
`

const Date = styled.time`
  margin-bottom: 0.875em;
  display: block;
  font-size: 1.125em;
  color: ${({ theme }) => theme.colors.metallic};
`

const Title = styled(Heading1)``

const Tagline = styled(ParagraphLarge)`
  margin-top: 0.75em;
`

const Content = styled(Styled)`
  ${({ theme }) => theme.grid.container(`xnarrow`)}
`

const InfoboxWrap = styled.div`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-top: ${rem(30)};

  &:empty {
    display: none;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${rem(20)};
  }
`

const Sites = styled.footer`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  > div {
    margin-top: ${rem(40)};
    padding-top: ${rem(40)};
    border-top: 1px solid ${({ theme }) => theme.colors.cream};
  }

  ${Label3} {
    margin-bottom: ${rem(20)};
  }
`

const MapWrap = styled.section`
  ${({ theme }) => theme.grid.container(`xnarrow`)}
  height: 80vh;
  min-height: ${rem(120)};
  max-height: ${rem(300)};
  z-index: 10;
  position: relative;
  margin-top: ${rem(40)};

  * {
    border-radius: ${rem(15)};
  }
`

const Other = styled.section`
  background-color: ${({ theme }) => theme.colors.lightGreen};

  > div {
    ${({ theme }) => theme.grid.container()}

    padding-top: ${rem(50)};
    padding-bottom: ${rem(50)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${rem(20)};
      padding-top: ${rem(20)};
      padding-bottom: ${rem(20)};
    }
  }

  ${Label3} {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    margin-bottom: ${rem(20)};
    color: ${({ theme }) => theme.colors.metallic};
  }
`

const OtherCta = styled.footer`
  margin-top: ${rem(30)};

  a {
    ${anchorStyles}

    &:hover {
      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      margin-right: 0.4em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      vertical-align: middle;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`
