import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import NewsCard from "../news-card"
import { Heading2 } from "../styled/heading"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function NewsLatest({ data, moreText, moreLink, heading }) {
  return (
    <Container>
      <Heading2 dangerouslySetInnerHTML={{ __html: heading }} />

      <Grid>
        {data.map((item, i) => (
          <li key={i}>
            <NewsCard data={item} />
          </li>
        ))}
      </Grid>

      {moreText && moreLink && (
        <More>
          <Link to={moreLink}>
            <span dangerouslySetInnerHTML={{ __html: moreText }} />

            <SvgArrowRight aria-hidden="true" />
          </Link>
        </More>
      )}
    </Container>
  )
}

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  > ${Heading2} {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    margin-bottom: ${rem(26)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-bottom: ${rem(16)};
    }
  }
`

const Grid = styled.ul`
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX(null, { nested: true })}
  ${({ theme }) => theme.grid.gridGutterY(16)}

  > li {
    ${({ theme }) => theme.grid.cell(4)}

    @media ${({ theme }) => theme.mq.xlargeDown} {
      ${({ theme }) => theme.grid.cell(6)}
    }

    @media ${({ theme }) => theme.mq.mediumDown} {
      ${({ theme }) => theme.grid.cell(12)}
    }

    > * {
      width: 100%;
    }
  }
`

const More = styled.div`
  margin-bottom: ${rem(20)};
  margin-top: ${rem(20)};
  text-transform: uppercase;

  a {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    color: ${({ theme }) => theme.colors.black};

    &:hover {
      color: ${({ theme }) => theme.colors.blue};

      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      ${anchorStyles}

      margin-right: 0.25em;

      &:hover {
        color: ${({ theme }) => theme.colors.blue};
      }
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      margin-top: -0.1em;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`
