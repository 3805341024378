import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import EventCard from "../components/event-card"
import { Heading1 } from "../components/styled/heading"
import { Paragraph } from "../components/styled/paragraph"

export default function EventsTemplate({
  data: { config, translations, events, img },
}) {
  const anyEvents = !!events.nodes?.length

  return (
    <Layout>
      <Container>
        {img && (
          <Image>
            <GatsbyImage
              image={img.childImageSharp.gatsbyImageData}
              alt={config.pages.events.title}
            />
          </Image>
        )}

        <Content>
          <Header>
            <Heading1
              dangerouslySetInnerHTML={{
                __html: config.pages.events.title,
              }}
            />
          </Header>

          {anyEvents && (
            <Grid>
              {events.nodes.map((event) => (
                <li key={event.id}>
                  <EventCard data={event} />
                </li>
              ))}
            </Grid>
          )}

          {!anyEvents && (
            <Paragraph
              dangerouslySetInnerHTML={{
                __html: translations.global.noContent,
              }}
            />
          )}
        </Content>
      </Container>
    </Layout>
  )
}

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.events.title }} />
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        noContent
      }
    }

    config: configJson {
      pages {
        events {
          title
        }
      }
    }

    events: allEventsJson(sort: { occurs_at: ASC }) {
      nodes {
        ...EventsCardFragment
      }
    }

    img: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/events.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
  }
`

const Container = styled.article`
  padding-bottom: ${rem(30)};
  background-color: ${({ theme }) => theme.colors.lightBlue};
`

const Image = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    max-height: ${rem(480)};
  }
`

const Header = styled.header`
  margin-bottom: ${rem(20)};
  padding-bottom: ${rem(20)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
`

const Content = styled.div`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }
`

const Grid = styled.ul`
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX(null, { nested: true })}
  ${({ theme }) => theme.grid.gridGutterY(30)}

  margin-top: ${rem(20)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: 0;
  }

  > li {
    ${({ theme }) => theme.grid.cell(4)}

    @media ${({ theme }) => theme.mq.mediumDown} {
      ${({ theme }) => theme.grid.cell(6)}
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      ${({ theme }) => theme.grid.cell(12)}
    }
  }
`
