import { rem } from "polished"

const searchResult = {
  br: rem(8),
  backgroundColor: ({ theme }) => theme.colors.white,
  labelColor: ({ theme }) => theme.colors.white,
  labelBackgroundColor: ({ theme }) => theme.colors.green,
  labelBr: rem(5),
}

export default searchResult

// const TAG_COLORS = {
//   __default__: {
//     text: `white`,
//     background: `metallic`,
//   },
//   // pages:
//   map: {
//     text: `white`,
//     background: `red`,
//   },
//   articles: {
//     text: `white`,
//     background: `purple`,
//   },
//   events: {
//     text: `white`,
//     background: `aquaBlue`,
//   },
//   news: {
//     text: `white`,
//     background: `gold`,
//   },
//   // types:
//   hiking: {
//     text: `white`,
//     background: `blue`,
//   },
//   climbing: {
//     text: `white`,
//     background: `blue`,
//   },
//   canoeing: {
//     text: `white`,
//     background: `blue`,
//   },
//   biking: {
//     text: `white`,
//     background: `blue`,
//   },
//   diving: {
//     text: `white`,
//     background: `blue`,
//   },
//   wintersports: {
//     text: `white`,
//     background: `blue`,
//   },
// }
